import { format } from 'date-fns';

import ACFImage from 'types/ACFImage';
import { HistoryChangeDirection } from 'types/hourly-workforce-index';

import HWICompareCardImage from './compare-card-image';
import HWITrendArrow from './trend-arrow-icon';

type HWIIndustryCompareCardProps = {
  backgroundColor: string
  changeValue: string
  dataDate: string
  direction: HistoryChangeDirection
  image: ACFImage
  industryName: string
  primaryColor: string
}

const HWIIndustryCompareCard = ({
  backgroundColor,
  changeValue,
  direction,
  image,
  industryName,
  primaryColor,
}:HWIIndustryCompareCardProps) => {

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));

  return (
    <div className="hwi--industry-compare-card">
      <h3 className="hwi-card--title animation--slide-up-fade-in">
        {industryName}
        <span>As of {format(lastSunday, 'MMM d, y')}</span>
      </h3>
      <HWITrendArrow direction={ direction } />
      <div className="hwi-industry-card--change-value animation--slide-down-fade-in" style={ { color: primaryColor } }>
        {changeValue}
      </div>
      <div className="hwi-industry-card--context-statement animation--slide-up-fade-in">
        % change year-over-year
      </div>
      <HWICompareCardImage backgroundColor={ backgroundColor } image={ image } />
    </div>
  );
};

export default HWIIndustryCompareCard;

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ChartDataset } from 'chart.js';
import { differenceInCalendarMonths, isSunday } from 'date-fns';
import { graphql, Link, useStaticQuery } from 'gatsby';

import PageTemplate from 'layouts/page-templates/hourly-workforce-index';

import HWIAPI from 'utils/api/hourly-workforce-index';

import ACFImage from 'types/ACFImage';
import { HistoryChangeDirection, HwiRawNationalIndustryPayloadData } from 'types/hourly-workforce-index';

import HWICardsRow from 'components/content-blocks/hourly-workforce-index/cards-row';
import HWIIndustryCompareCard from 'components/content-blocks/hourly-workforce-index/industry-compare-card';
import HWILineChart from 'components/content-blocks/hourly-workforce-index/line-chart';
import HWIGraphPlaceholder from 'components/content-blocks/hourly-workforce-index/placeholders/graph';
import HWILargeCardPlaceholder from 'components/content-blocks/hourly-workforce-index/placeholders/large-card';
import formatChangeValue from 'components/content-blocks/hourly-workforce-index/util/format-change-value';

import { hwiPageUrl } from '.';

function mapIndustryDataToGraphPoints(data: HwiRawNationalIndustryPayloadData[]): { x: number, y: number }[] {
  return data
    .filter(point => {
      const days = new Date(point.day);
      if (isSunday(days)) {
        return days.getDate();
      }
    })
    .filter(point => differenceInCalendarMonths(new Date(), new Date(point.day)) <= 12)
    .map(point => ({ x: new Date(point.day).getTime(), y: point.yoyEmployment }));
}

type ACFHWIIndustry = {
  image: ACFImage
  key: string
  name: string
  primary_color: string
  secondary_color: string
}

type PageContext = {
  acf: {
    hwi_industries: {
      industry: Array<{
        image: ACFImage
        key: string
        name: string
        primary_color: string
        secondary_color: string
      }>
      industry_label: string
    }
  }
}

const HourlyWorkforceIndexIndustryPage = () => {

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "hourly-workforce-index" } }) {
        edges {
          node {
            wordpress_id
            acf {
              hwi_industries {
                industry_label
                industry {
                  key
                  name
                  image {
                    alt_text
                    source_url
                  }
                  primary_color
                  secondary_color
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext: PageContext = data.allWordpressPage.edges[0].node;
  const industries: ACFHWIIndustry[] = pageContext.acf.hwi_industries.industry;

  const industryDataQuery = useQuery(['hwi-industry'], () => HWIAPI.GetAllIndustryData());

  const foodServiceData = useMemo(() => {
    if (!industryDataQuery.data) return undefined;
    const { foodService } = industryDataQuery.data;
    return foodService.payload.data[foodService.payload.data.length - 1];
  }, [industryDataQuery.data]);

  const hospitalityData = useMemo(() => {
    if (!industryDataQuery.data) return undefined;
    const { hospitality } = industryDataQuery.data;
    return hospitality.payload.data[hospitality.payload.data.length - 1];
  }, [industryDataQuery.data]);

  const retailData = useMemo(() => {
    if (!industryDataQuery.data) return undefined;
    const { retail } = industryDataQuery.data;
    return retail.payload.data[retail.payload.data.length - 1];
  }, [industryDataQuery.data]);

  const graphData: ChartDataset[] | undefined = useMemo(() => {
    if (!industryDataQuery.data) return undefined;

    return [
      {
        backgroundColor: industries[0].primary_color,
        borderColor: industries[0].primary_color,
        cubicInterpolationMode: 'monotone',
        data: mapIndustryDataToGraphPoints(industryDataQuery.data.foodService.payload.data),
        fill: false,
        indexAxis: 'x',
        label: industries[0].name,
      },
      {
        backgroundColor: industries[1].primary_color,
        borderColor: industries[1].primary_color,
        borderDash: [15, 5], // length and spacing
        borderJoinStyle: 'round',
        borderWidth: 3.5,
        cubicInterpolationMode: 'monotone',
        data: mapIndustryDataToGraphPoints(industryDataQuery.data.hospitality.payload.data),
        fill: false,
        indexAxis: 'x',
        label: industries[1].name,
      },
      {
        backgroundColor: industries[2].primary_color,
        borderColor: industries[2].primary_color,
        borderDash: [7, 3], // length and spacing
        borderJoinStyle: 'round',
        borderWidth: 3.5,
        cubicInterpolationMode: 'monotone',
        data: mapIndustryDataToGraphPoints(industryDataQuery.data.retail.payload.data),
        fill: false,
        indexAxis: 'x',
        label: industries[2].name,
      },
    ];
  }, [industryDataQuery.data]);

  return (
    <PageTemplate>
      <section className="hwi--view-wrapper" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container container-large is-max-desktop">
          <div className="hwi--data-container">
            <div className="hwi--view-indicator">
              <div className="hwi--current-view-label">{pageContext.acf.hwi_industries.industry_label}</div>
            </div>
            <div className="hwi--view-by-links">
              <Link className="button is-green is-rounded" to={ hwiPageUrl }>View By State</Link>
            </div>
            <div className="hwi--main-chart">
              {graphData ? <HWILineChart dataSets={ graphData } /> : <HWIGraphPlaceholder />}
            </div>
            <HWICardsRow
              first={
                foodServiceData ? (
                  <HWIIndustryCompareCard
                    backgroundColor={ industries[0].secondary_color }
                    changeValue={ formatChangeValue(foodServiceData.yoyEmployment) }
                    dataDate={ foodServiceData.day }
                    direction={ foodServiceData.yoyEmployment > 0 ? HistoryChangeDirection.GAIN : HistoryChangeDirection.DROP }
                    image={ industries[0].image }
                    industryName={ industries[0].name }
                    primaryColor={ industries[0].primary_color }
                  />
                ) : (
                  <HWILargeCardPlaceholder showArc />
                )
              }
              second={ 
                hospitalityData ? (
                  <HWIIndustryCompareCard
                    backgroundColor={ industries[1].secondary_color }
                    changeValue={ formatChangeValue(hospitalityData.yoyEmployment) }
                    dataDate={ hospitalityData.day }
                    direction={ hospitalityData.yoyEmployment > 0 ? HistoryChangeDirection.GAIN : HistoryChangeDirection.DROP }
                    image={ industries[1].image }
                    industryName={ industries[1].name }
                    primaryColor={ industries[1].primary_color }
                  />
                ) : (
                  <HWILargeCardPlaceholder showArc />
                )
              }
              third={
                retailData ? (
                  <HWIIndustryCompareCard
                    backgroundColor={ industries[2].secondary_color }
                    changeValue={ formatChangeValue(retailData.yoyEmployment) }
                    dataDate={ retailData.day }
                    direction={ retailData.yoyEmployment > 0 ? HistoryChangeDirection.GAIN : HistoryChangeDirection.DROP }
                    image={ industries[2].image }
                    industryName={ industries[2].name }
                    primaryColor={ industries[2].primary_color }
                  />
                ) : (
                  <HWILargeCardPlaceholder showArc />
                )
              }
            />
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

export default HourlyWorkforceIndexIndustryPage;
